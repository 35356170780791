import styled from '@emotion/styled';
import { Container, Link, MaxWidth, StyledText } from '$shared/components';
import { breakpoints } from '~/theme/breakpoints';
import { ifProp } from 'styled-tools';
import { StyledResetButton } from '~/shared/components/Button/styled';

type StyledIconButtonProps = {
    active?: boolean;
    mobileOnly?: boolean;
    small?: boolean;
    full?: boolean;
    desktop?: boolean;
    textColor?: string;
    reverseIcon?: boolean;
};

export const StyledContent = styled(Container)<{
    topBanner?: boolean;
    desktop?: boolean;
}>(
    ({ theme }) => ({
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        [breakpoints.xs]: {
            padding: `${theme.space[2]} ${theme.space[2]}`,
        },
    }),
    ifProp('topBanner', ({ theme }) => ({
        padding: 0,
        maxWidth: 'unset',
        [breakpoints.xs]: {
            marginTop: theme.space[2],
            padding: `${theme.space[2]} 0 0 0`,
        },
    })),
);
export const StyledTopBanner = styled(MaxWidth)<{
    textColor?: string;
}>(({ theme, textColor }) => ({
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    boxSizing: 'border-box',
    color: textColor ? textColor : theme.colors.white,
    justifyContent: 'center',
    minHeight: theme.sizes['banner'],
}));

export const StyledTopBannerContainer = styled.div<{
    textColor?: string;
    bannerBackGroundColor?: string;
}>(({ theme, bannerBackGroundColor }) => ({
    backgroundColor: bannerBackGroundColor ? bannerBackGroundColor : theme.colors.headerColor,
    height: `${theme.metrics.medium}`,
    justifyContent: 'center',
}));

export const StyledTopBannerText = styled(StyledText.withComponent('span'))<{
    textColor: string;
    withCloseBanner?: boolean;
    topBanner?: boolean;
}>(({ theme, textColor }) => ({
    color: textColor ? textColor : theme.colors.white,
    ...theme.mixins.useTextStyle('bodySmall'),
    textAlign: 'center',
    textJustify: 'auto',
    display: 'flex',
    alignItems: 'center',
}));
export const StyledCampaignBanner = styled(MaxWidth)<{
    textColor?: string;
    bannerBackGroundColor?: string;
}>(({ theme, bannerBackGroundColor, textColor }) => ({
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    boxSizing: 'border-box',
    backgroundColor: bannerBackGroundColor ? bannerBackGroundColor : theme.colors.headerColor,
    color: textColor ? textColor : theme.colors.white,
    justifyContent: 'center',
    minHeight: theme.sizes['banner'],
    maxWidth: 'unset',
    padding: `0px ${theme.space[6]}`,
}));
export const StyledCampaignBannerTextLink = styled(Link)<{
    textColor: string;
    withCloseBanner?: boolean;
    showTopBanner?: boolean;
    desktop?: boolean;
    withDecoration?: boolean;
    justifyContent?: string;
}>(
    ({ theme, textColor }) => ({
        color: textColor ? textColor : theme.colors.white,
        fontSize: theme.fontSizes['xs'],
        textAlign: 'center',
        textJustify: 'auto',
        display: 'flex',
        flexGrow: 1,
        flexBasis: 0,
        margin: '0 auto',
        letterSpacing: 0,
        alignItems: 'center',
        justifyContent: 'center',
        lineHeight: theme.lineHeights['base'],
        padding: theme.space[2],
        [breakpoints.xs]: {
            lineHeight: theme.lineHeights['base'],
        },
        ':hover': {
            color: theme.colors.white,
            textDecoration: 'none',
        },
    }),
    ifProp('desktop', ({ theme }) => ({
        padding: theme.space[2],
        marginTop: 'unset',
        lineHeight: `${theme.space[3]}`,
    })),
    ifProp('withDecoration', () => ({
        textDecoration: 'underline',
    })),
);
export const StyledCampaignBannerText = styled(StyledText)<{
    textColor: string;
    withCloseBanner?: boolean;
    showTopBanner?: boolean;
    desktop?: boolean;
}>(
    ({ theme, textColor }) => ({
        color: textColor ? textColor : theme.colors.white,
        fontSize: theme.fontSizes['xs'],
        textAlign: 'center',
        textJustify: 'auto',
        display: 'flex',
        letterSpacing: 0,
        alignItems: 'center',
        lineHeight: theme.lineHeights['base'],
        padding: 0,
        [breakpoints.xs]: {
            lineHeight: theme.lineHeights['base'],
            fontSize: theme.fontSizes['xs'],
        },
    }),
    ifProp('desktop', ({ theme }) => ({
        padding: 'unset',
        marginTop: 'unset',
        lineHeight: `${theme.space[5]}`,
    })),
);
export const StyledIconButton = styled(StyledResetButton)<StyledIconButtonProps>(
    ({ theme }) => ({
        display: 'block',
        padding: `${theme.space[4]} ${theme.space[2]}`,
        position: 'relative',
        '&:hover': {
            backgroundColor: theme.colors.grey30,
        },

        '&:active': {
            backgroundColor: theme.colors.grey50,
        },
        svg: {
            height: 24,
            width: 24,
        },
    }),
    ifProp('active', ({ theme }) => ({
        backgroundColor: theme.colors.grey30,
        '&:hover': {
            backgroundColor: theme.colors.grey40,
        },
    })),
    ifProp('mobileOnly', {
        svg: {
            height: 16,
            width: 16,
        },
        [breakpoints.sm]: {
            display: 'none',
        },
    }),
);
export const StyledIconButtonBanner = styled(StyledIconButton)<StyledIconButtonProps>(
    ({ theme }) => ({
        padding: `${theme.space[1]} ${theme.space[1]} ${theme.space[1]} ${theme.space[1]}`,
        '&:hover': {
            backgroundColor: 'transparent',
        },
        svg: {
            height: '16px',
            width: '16px',
            margin: `${theme.space[1]} ${theme.space[2]} 0 ${theme.space[2]}`,
        },
        [breakpoints.xs]: {
            display: 'none',
            padding: 0,
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
        [breakpoints.sm]: {
            display: 'none',
            padding: 0,
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
        [breakpoints.md]: {
            display: 'flex',
        },
    }),
);
