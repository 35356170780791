'use client';

import React, { FC } from 'react';
import { css } from '@emotion/react';
import { theme } from '~/theme';
import { useTranslation } from '~/shared/utils/translation';
import { ModuleContainer } from '~/templates/blocks/components/ModuleContainer';
import { Text } from '~/shared/components';
import { useSearchParams } from 'next/navigation';

type Props = {
    error: Error;
};

const errorCss = css({
    color: theme.colors.negative,
});

const ErrorBoundaryFallback: FC<Props> = ({ error }) => {
    const { translate } = useTranslation();
    const searchParams = useSearchParams();
    const showErrorMessage = searchParams?.get('showErrorMessage');

    const isDebug = showErrorMessage === 'true' || (process.env.NEXT_PUBLIC_DEBUG ?? false);

    return (
        <ModuleContainer>
            <Text variant="large">{translate('message.contentUnavailable')}</Text>
            {isDebug && (
                <Text variant="caption">
                    <pre css={errorCss}>{error.message}</pre>
                </Text>
            )}
        </ModuleContainer>
    );
};

export default ErrorBoundaryFallback;
