import styled from '@emotion/styled';
import { Link } from '~/shared/components';
import { ifProp } from 'styled-tools';
import { Button } from '$shared/components/Button/Button';
import { breakpoints } from '~/theme/breakpoints';

export const StyledNavigationItem = styled(Button)<{ active?: boolean }>(
    ({ theme }) => ({
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        margin: 'auto',
        //opacity: 0.6,
        padding: `${theme.space[2]} 0`,
        color: theme.colors.black,
        fontSize: theme.fontSizes.sm,
        fontWeight: theme.fontWeights.medium,
        lineHeight: theme.lineHeights.base,
        textTransform: 'uppercase',
        borderBottom: '2px solid transparent',
        backgroundColor: 'transparent',
        textDecoration: 'none',
        '&:hover': {
            opacity: 1,
            color: 'currentcolor',
            textDecoration: 'none',
            backgroundColor: 'transparent',
            borderRadius: 0,
        },
        [breakpoints.md]: {
            paddingTop: 0,
            borderRadius: 0,
        },
    }),
    ifProp('active', ({ theme: { colors } }) => ({
        borderBottom: `2px solid ${colors.black}`,
        opacity: 1,
        backgroundColor: 'transparent',
        paddingTop: 0,
        borderRadius: 0,
        zIndex: 100,
    })),
);

export const StyledLinkNavigationItem = StyledNavigationItem.withComponent(Link);
