import { breakpoints } from '~/theme/breakpoints';
import styled from '@emotion/styled';
import { ifProp, switchProp } from 'styled-tools';
import { StyledAlignContent } from '../AlignContent';
import { M10HeroProps } from './M10Hero';

type StyledM10Props = Pick<M10HeroProps, 'backgroundColor' | 'height'>;
type StyledM10ContentProps = {
    height: M10HeroProps['height'];
    mediaSize: M10HeroProps['mediaLayout'];
    mediaAlignment: M10HeroProps['mediaHorizontalAlignment'];
    contentAlignment: M10HeroProps['horizontalAlignment'];
};

export const StyledMediaWrapper = styled.div({
    position: 'relative',
    height: '100%',
    width: '100%',
});

export const StyledHeroContent = styled.div({
    zIndex: 1,
});

export const StyledM10 = styled.div<StyledM10Props>(
    ({ theme, backgroundColor }) => ({
        position: 'relative',
        width: '100%',
        display: 'grid',
        maxWidth: theme.sizes.pageMaxWidth,
        margin: '0 auto',
        backgroundColor,
        [`${StyledAlignContent}`]: {
            padding: theme.space['4'],
            [breakpoints.md]: {
                padding: theme.space['7'],
            },
        },
    }),

    switchProp('height', {
        narrow: {
            minHeight: 160,
            [breakpoints.md]: {
                minHeight: 370,
            },
        },
        normal: {
            minHeight: 400,
            [breakpoints.md]: {
                minHeight: 600,
            },
        },
        tall: {
            minHeight: 600,
            [breakpoints.md]: {
                minHeight: 800,
            },
        },
    }),
);

export const StyledM10Content = styled.div<StyledM10ContentProps>(
    {
        height: 'auto',
        maxHeight: 1200,
        width: '100%',
        margin: '0 auto',
        display: 'grid',
        [breakpoints.xs]: {
            maxWidth: '100vw',
        },
    },

    ifProp({ mediaSize: 'split' }, ({ theme, mediaAlignment }) => {
        if (!mediaAlignment) {
            return;
        }
        const capitalizedMediaAlignment =
            mediaAlignment === 'left' ? 'Left' : mediaAlignment === 'right' ? 'Right' : '';
        return {
            [`${StyledAlignContent}`]: {
                [breakpoints.sm]: {
                    [`padding${capitalizedMediaAlignment}`]: theme.space['4'],
                },
                [breakpoints.md]: {
                    [`padding${capitalizedMediaAlignment}`]: theme.space['7'],
                },
            },
        };
    }),

    ifProp(
        { contentAlignment: 'center' },
        {
            justifyContent: 'center',
        },
    ),

    switchProp('mediaSize', {
        full: {
            [`${StyledMediaWrapper}`]: {
                top: 0,
                left: 0,
                position: 'absolute',
            },
            [`${StyledAlignContent}`]: {
                width: '100%',
                margin: '0 auto',
                zIndex: 1,
            },
        },

        split: ({ mediaAlignment }) => ({
            display: 'flex',
            flexDirection: 'column-reverse',
            [`${StyledMediaWrapper}`]: {
                position: 'relative',
                width: `100%`,
                aspectRatio: '1.35',
            },
            [breakpoints.md]: {
                display: 'grid',
                gridTemplateColumns: '50%',
                justifyContent: mediaAlignment === 'left' ? 'end' : 'start',
                [`${StyledMediaWrapper}`]: {
                    padding: 0,
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    width: 'auto',
                    aspectRatio: 'unset',
                    left: mediaAlignment === 'left' ? 0 : '50%',
                    right: mediaAlignment === 'right' ? 0 : '50%',
                },
            },
        }),
    }),
);
