import React, { InputHTMLAttributes, useImperativeHandle, useRef } from 'react';
import { useInputField } from '../../../../../utils';
import { controllableInputElementsProps } from '../../../index';
import { StyledInvalidMessage } from '../../invalid-message';
import { StyledHelpText } from '../../help-text';
import styles from './switch-element.module.scss';
import clsx from 'clsx';

export interface SwitchElementProps
    extends Omit<InputHTMLAttributes<HTMLInputElement>, 'value' | 'placeholder'>,
        controllableInputElementsProps {
    hideInvalidMessage?: boolean;
    title?: string;
}

export const SwitchElement = React.forwardRef<HTMLInputElement, SwitchElementProps>(
    (
        {
            label,
            helpText,
            title,
            isInvalid,
            invalidMessage,
            id,
            hideInvalidMessage,
            className,
            ...rest
        },
        ref,
    ) => {
        const inputRef = useRef<HTMLInputElement | null>(null);
        useImperativeHandle(ref, () => inputRef.current as HTMLInputElement, [ref]);

        const { fieldId, helpTextId, describedById, showHelpText } = useInputField({
            id,
            helpText,
            isInvalid,
            invalidMessage,
        });

        const showInvalidMessage = invalidMessage && !hideInvalidMessage;

        return (
            <fieldset className={styles.fieldset} disabled={rest.disabled}>
                <label className={styles.wrapper} key={fieldId} tabIndex={0}>
                    {label && (
                        <legend
                            className={clsx(styles.legend, {
                                [styles.invalid]: isInvalid,
                                [styles.valid]: !isInvalid,
                                [styles.disabled]: rest?.disabled,
                            })}
                            title={title}
                        >
                            {label}
                        </legend>
                    )}

                    <input
                        {...rest}
                        type="checkbox"
                        id={fieldId}
                        ref={inputRef}
                        aria-describedby={describedById}
                        className={clsx(styles.input, className)}
                    />

                    <span className={styles.switchIndicator} />
                </label>

                {showInvalidMessage && <StyledInvalidMessage children={invalidMessage} />}
                {showHelpText && <StyledHelpText id={helpTextId} children={helpText} />}
            </fieldset>
        );
    },
);

export default SwitchElement;
