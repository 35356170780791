'use client';

import { Module } from '$lib';
import React, { FC, useEffect } from 'react';
import { N20MainMenu, N35MobileMegaMenu } from '~/features/navigation';
import N60Footer from '~/features/navigation/components/N60Footer';
import Breadcrumbs from '~/shared/components/Breadcrumb/Breadcrumb';
import { useFrame } from '~/shared/utils';
import { theme } from '~/theme';
import { MetaFields } from '../../../MetaFields';
import { TopButton } from '../../../TopButton/TopButton';
import { useBannerState } from '~/features/navigation/hooks/useBannerState';
import { StyledMain } from './styled';
import dynamic from 'next/dynamic';
import { useSessionStorage } from 'react-use';
import { useDynamicHooks } from '../../hooks/useDynamicHooks';
import { useGoogleTagManagerScript } from '~/shared/scripts/useGoogleTagManager';
import { usePage } from '~/templates/pages/hooks/usePage';
import { StandardPageSchema } from '~/features/standard-page/components/StandardPageSchema';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorBoundaryFallback from '~/templates/blocks/components/ErrorBoundaryFallback/ErrorBoundaryFallback';
import { useIsMobile } from '~/shared/hooks/useIsMobile/useIsMobile';

const Scripts = dynamic(() => import('../Scripts'), {
    ssr: false,
});

const LoginDrawer = dynamic(() => import('../LoginDrawer'), {
    ssr: false,
});

const BasketDrawer = dynamic(() => import('../BasketDrawer'), {
    ssr: false,
});

const Toast = dynamic(() => import('../Toast'), {
    ssr: false,
});

const Standard: FC = ({ children }) => {
    const { data } = useFrame();
    const page = usePage();
    const isMobile = useIsMobile();
    // const showSearch = useSearchStore(showSearchSelector);
    const { setIsBannerOpen, isBannerOpen } = useBannerState();
    const [originalLocation, setOriginalLocation] = useSessionStorage('originalLocation');

    useGoogleTagManagerScript({
        frame: data,
        page: page,
    });

    // const { setSearchState, setQuery, setShowSearch } = useSearchStore(
    //     (x) => ({
    //         setSearchState: x.setSearchState,
    //         setQuery: x.setQuery,
    //         setShowSearch: x.setShowSearch,
    //     }),
    //     shallow
    // );

    useDynamicHooks();

    useEffect(() => {
        setOriginalLocation(originalLocation || window.location.href);
    }, []);

    useEffect(() => {
        if (isBannerOpen) {
            setIsBannerOpen(!!data.header?.campaignBannerLink);
        }
    }, [data]);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { breadcrumb, heroHeader, heroTheme, pageElements, type: pageType } = usePage();

    const firstModule = pageElements?.[0] as Module | undefined;
    const firstModuleIsHero =
        !!firstModule?.type &&
        ['m10HeroModule', 'm70MediaModule', 'm75MediaAndTextModule'].includes(firstModule.type);
    const breadcrumbsShouldFloat =
        firstModuleIsHero ||
        !!heroHeader ||
        pageType === 'p10SearchPage' ||
        pageType === 'p160ArticlePage' ||
        pageType === 'giftCardBalancePage';

    let breadcrumbsColor = (heroTheme === 'Light' ? theme.colors.white : theme.colors.black) as
        | typeof theme.colors.white
        | typeof theme.colors.black;
    if (!heroHeader && firstModuleIsHero) {
        breadcrumbsColor =
            firstModule?.textColor?.toUpperCase() === theme.colors.white
                ? theme.colors.white
                : theme.colors.black;
    }

    if (firstModuleIsHero && firstModule) {
        firstModule.spacingTop = 'none';
    }

    return (
        <>
            <MetaFields />
            <Scripts />
            {page.meta?.schemaMarkup && (
                <StandardPageSchema schemaMarkup={page.meta?.schemaMarkup} />
            )}
            <N20MainMenu showTopBanner={!!data.header?.topBanner} />
            <N35MobileMegaMenu />
            <ErrorBoundary key={page.id} FallbackComponent={ErrorBoundaryFallback}>
                <StyledMain>
                    {breadcrumb?.items && breadcrumb.items.length > 1 ? (
                        <Breadcrumbs
                            items={breadcrumb.items}
                            shouldFloat={breadcrumbsShouldFloat}
                            color={breadcrumbsShouldFloat ? breadcrumbsColor : undefined}
                        />
                    ) : null}
                    {children}
                    <LoginDrawer />
                    <BasketDrawer />
                    <Toast />
                    {isMobile && <TopButton />}
                </StyledMain>
            </ErrorBoundary>
            <N60Footer />
        </>
    );
};

export default Standard;
