import type { P40ProductListPage } from '~/lib';
import { renderToString } from 'react-dom/server';
import { generateAlgoliaConfigProps } from '~/features/productList/Algoliga.utils';
import type { ParsedUrlQuery } from 'querystring';
import { InstantSearch, Configure, Index, getServerState } from 'react-instantsearch';
import { MainAlgoliaIndex, algoliaClient, pathToSearchState } from '~/lib/algolia';
import { IncomingMessage } from 'http';
import { NextApiRequestCookies } from 'next/dist/server/api-utils';

type Props = {
    req: IncomingMessage & {
        cookies: NextApiRequestCookies;
    };
    page: P40ProductListPage;
    query: ParsedUrlQuery;
};

const generateFacetFilters = (searchState: any) => {
    const { refinementList, toggle } = searchState;
    const filters = [refinementList, toggle].filter(Boolean);
    if (!filters.length) return null;
    const f = filters.map((item: Record<string, any>) => {
        return Object.entries(item).reduce((acc, [key, value]) => {
            if (value.length > 0) {
                acc.push(`${key}:${value}`);
            }
            return acc;
        }, [] as string[]);
    });
    return f.flat();
};

export const plpDataResolver = async ({ req, page, query }: Props) => {
    const { dataConfiguration, dataType, parentDataConfiguration, parentDataType } = page;
    const { productids, productIds, campaign } = query;

    const searchState = pathToSearchState(req.url);
    const facetFilters = generateFacetFilters(searchState) ?? [];

    // Extract page number from search state
    const pageParam = searchState.page;
    const pageValue = Array.isArray(pageParam) ? pageParam[0] : pageParam;

    const pageNumber =
        typeof pageValue !== 'string' && typeof pageValue !== 'number'
            ? 0
            : Math.max(0, Number(pageValue) - 1);

    const algoliaProps = generateAlgoliaConfigProps({
        filter: dataConfiguration,
        filterType: dataType,
        parentFilter: parentDataConfiguration,
        parentFilterType: parentDataType,
        productIds: productids ?? productIds,
        campaign,
    });

    const protocol = req.headers.referer?.split('://')[0] ?? 'https';
    const serverUrl = `${protocol}://${req.headers.host}${req.url}`;

    const serverState = await getServerState(
        <InstantSearch indexName={MainAlgoliaIndex} searchClient={algoliaClient}>
            <Index indexName={MainAlgoliaIndex} indexId="products">
                <Configure
                    {...algoliaProps}
                    hitsPerPage={20}
                    page={pageNumber}
                    clickAnalytics
                    facetFilters={facetFilters}
                />
            </Index>
            <Index indexName={MainAlgoliaIndex} indexId="facets">
                <Configure
                    facets={['*']}
                    ruleContexts={algoliaProps.ruleContexts}
                    hitsPerPage={0}
                />
            </Index>
        </InstantSearch>,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        { renderToString },
    );

    return { serverState, serverUrl };
};
