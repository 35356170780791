import { useMemo } from 'react';
import { MediaAndTextItem, PageReference } from '~/lib/data-contract';

/**
 * Maps reference items to media and text items
 *
 * @param items mixed models of IMediaAndTextItem & PageReference
 * @returns Array of IMediaAndTextItem
 */
export const useItemMapper = (items: (MediaAndTextItem | PageReference)[]): MediaAndTextItem[] => {
    const mapped = useMemo(() => {
        return items.map((item) => {
            return isPageReference(item)
                ? {
                      headline: item.pageTitle,
                      description: `<p>${item.pageDescription}</p>`,
                      image: item.pageImage,
                      callToAction: item.callToAction,
                  }
                : item;
        });
    }, [items]);

    return mapped;
};

const isPageReference = (item: MediaAndTextItem | PageReference): item is PageReference => {
    return item.alias === 'pageReference';
};
