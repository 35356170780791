import styled from '@emotion/styled';
import { breakpoints } from '~/theme/breakpoints';

export const StyledArticle = styled.div({
    width: '230px',
    'p:last-of-type': {
        marginBottom: 0,
    },
    [breakpoints.md]: {
        width: '340px',
    },
});

export const StyledActionWrapper = styled.div({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    marginTop: 10,
    gap: 20,
});

export const StyledContent = styled.div({
    display: 'flex',
    flexDirection: 'column',
    marginTop: 10,
    gap: 20,
});
