import React from 'react';
import { M80MediaAndTextBundleModule } from '~/lib/data-contract';
import { Gutter, MaxWidth } from '~/shared/components';
import {
    StyledScrollArea,
    StyledScrollbar,
    StyledThumb,
    StyledViewport,
} from '~/shared/components/ScrollArea/styled';
import { ModuleContainer } from '../ModuleContainer';
import { ModuleHeader } from '../ModuleHeader';
import { Article } from './components/Article';
import { useItemMapper } from './hooks';
import { StyledArticleWrapper } from './styled';
import { useModuleTheme } from '../../hooks/useModuleTheme';

export type M80MediaTextBundleProps = M80MediaAndTextBundleModule;

export const M80MediaTextBundle = ({
    headline,
    callToAction,
    mediaAndTextItems,
    ...rest
}: M80MediaTextBundleProps) => {
    const articles = useItemMapper(mediaAndTextItems);
    const { textColor } = useModuleTheme(rest);
    return (
        <ModuleContainer fullWidth {...rest}>
            <StyledScrollArea type="auto">
                <MaxWidth>
                    <Gutter>
                        <ModuleHeader
                            headline={headline}
                            link={callToAction}
                            textColor={textColor}
                        />
                    </Gutter>
                </MaxWidth>

                <StyledViewport>
                    <MaxWidth>
                        <Gutter>
                            <StyledArticleWrapper>
                                {articles.map((mediaText, index) => (
                                    <Article key={index} {...mediaText} />
                                ))}
                            </StyledArticleWrapper>
                        </Gutter>
                    </MaxWidth>
                </StyledViewport>

                <MaxWidth>
                    <Gutter>
                        <div css={{ position: 'relative' }}>
                            <StyledScrollbar orientation="horizontal">
                                <StyledThumb />
                            </StyledScrollbar>
                        </div>
                    </Gutter>
                </MaxWidth>
            </StyledScrollArea>
        </ModuleContainer>
    );
};
