'use client';
import React, { forwardRef } from 'react';
import styles from './MaxWidth.module.scss';
import clsx from 'clsx';

interface StyledMaxWidthProps {
    children: React.ReactNode;
    className?: string;
}

export const MaxWidth = forwardRef<HTMLDivElement, StyledMaxWidthProps>(
    ({ children, className, ...props }, ref) => (
        <div ref={ref} className={clsx(styles.maxWidth, className)} {...props}>
            {children}
        </div>
    ),
);

MaxWidth.displayName = 'MaxWidth';
